import * as React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import styled from "styled-components"
import { Row, Col, Container } from "react-bootstrap"
import MyGoogleMap from "../components/GoogleMaps"
import { motion } from "framer-motion"

const InfoPage = () => (
  <Layout>
    <MyGoogleMap />
    <Container>
      <h2 className="about-title">About</h2>
      <hr />
      <Row>
        <div>
          <p>
            Hyphenate is an accredited member of&nbsp;
            <strong>
              <a
                className="infolink"
                href="https://www.aicp.com/membership-information/member-directory/general-member-directory"
                target="_blank"
                rel="noopener noreferrer"
              >
                AICP
              </a>
            </strong>
            , operating with the highest level of integrity and upholding all
            best practices across every aspect of our business and industry.
            Artist first.
          </p>
        </div>
        <h4>Team</h4>
        <hr />
        <Col lg={4}>
          <h4>TRAVIS BUTTON</h4>
          <p>Principal Visual Effects Director</p>

          <a className="infolink" href="mailto:travis@hyphenate.tv">
            travis@hyphenate.tv
          </a>
        </Col>
        <Col lg={4}>
          <h4>DURAN CASTRO</h4>
          <p>Lead Colorist</p>

          <a className="infolink" href="mailto:duran@hyphenate.tv">
            duran@hyphenate.tv
          </a>
        </Col>
        <Col lg={4}>
          <h4>GENERAL / SALES</h4>
          <p>Hyphenate — It</p>

          <a className="infolink" href="mailto:hyphenate-it@hyphenate.tv">
            hyphenate-it@hyphenate.tv
          </a>
        </Col>
      </Row>
      <h4>Reps</h4>
      <hr />
      <Row>
        <Col lg={4}>
          <h4>
            KAREN LEWELLEN
            <br />
            IT'S KAREN!
          </h4>
          <p>US VFX/Animation Representation</p>

          <a
            className="infolink"
            href="mailto:karenl@itskaren.com?subject=[HYPHENATE]"
          >
            karenl@itskaren.com
          </a>
          <br />
          <a className="infolink" href="tel:3109905121">
            (310) 990 5121
          </a>
        </Col>
        <Col lg={4}>
          <h4>
            DOUG RIGGS
            <br />
            BRAWLER LA
          </h4>
          <p>West Coast Director Representation</p>

          <a
            className="infolink"
            href="mailto:hello@brawler.la?subject=[DIRECTOR TRAVIS BUTTON]"
          >
            hello@brawler.la
          </a>
          <br />
          <a className="infolink" href="tel:6507731708">
            (650) 773 1708
          </a>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <h4>Visit</h4>
          <hr />
          <a
            className="infolink"
            href="https://goo.gl/maps/W4wkfFhaSZ2tXQTA8"
            target="_blank"
            rel="noopener noreferrer"
          >
            915 MATEO ST SUITE 308,
            <br />
            LOS ANGELES, CA 90021&nbsp;
          </a>
          <br />
          <a className="infolink" href="tel:3232056139">
            (323) 205 6139
          </a>
        </Col>
        <Col lg={6}>
          <h4>Careers</h4>
          <hr />
          <p>
            Think you may be a good fit with us here at Hypheante? We’d love to
            hear from you!
          </p>
          <br />
          <a className="infolink" href="mailto:careers@hyphenate.tv">
            careers@hyphenate.tv
          </a>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export const Head = () => <SEO title="Info" />
export default InfoPage
