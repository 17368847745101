import React from "react"
import { useState } from "react"
import markerLogo from "../images/hyphenate-icon-96.png"
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"

const containerStyle = {
  width: "100vw",
  height: "50vh",
}

const center = {
  lat: 34.032035,
  lng: -118.232518,
}
const position = { lat: 34.033, lng: -118.2348472 }

function MyGoogleMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAItlBpKEoE7Tcn0rTtoXjhdEbguMacFl0",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [isShown, setIsShown] = useState(false)

  const handleClick = event => {
    // 👇️ toggle shown state
    setIsShown(current => !current)

    // 👇️ or simply set it to true
    // setIsShown(true);
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      /* options={{
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      }} */

      //These two are causing the map to reset on refresh
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      <Marker
        icon={markerLogo}
        onClick={handleClick}
        className="map-marker"
        position={{ lat: 34.0318689, lng: -118.2348471 }}
      ></Marker>
      {/* 👇️ show elements on click */}
      {isShown && (
        <InfoWindow
          style={{ marginbottom: "30px" }}
          onLoad={onLoad}
          position={position}
        >
          <div>
            <h4 style={{ color: "black" }}>Hyphenate</h4>
            <div style={{ color: "black" }}>
              915 Mateo St. Suite 308<br></br>Los Angeles CA, 90021
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(MyGoogleMap)
